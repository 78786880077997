import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {actionValue: String, siteKey: String}

  connect() {
    turnstile.render(this.element, {
      action: this.actionValue,
      appearance: "interaction-only",
      sitekey: this.siteKeyValue,
      theme: "light"
    });
  }
}
